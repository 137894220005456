@import "./fonts/sf-pro.scss";
@import "_bootstrap";
@import "_variables";
@import "./dark-mode.scss";

html, body, #root {
  min-height: 100vh;
}

.Wellness {
  &Nav {
    min-height: 20vh;
  }

  &Body {
    min-height: 30vh;
    flex-direction: column;
  }
}

.wellness-icon {
  align-self: flex-start;
  justify-self: flex-start;
  margin-left: 40px;
  margin-top: 40px;
}

.location-dropdown {
  min-width: 280px;
  padding-top: 1.5em;

  #locationSelect {
    width: 100%;
    text-align: left;

    &:after {
      float: right;
      margin-top: .5em;
    }
  }

  .dropdown-menu {
    width: 100%;
  }
}

.chevron::after {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  transform: rotate(-45deg);
  vertical-align: middle;
  width: 0.45em;
  margin-left: .5em;
}

.chevron.right:after {
  left: 0;
  transform: rotate(45deg);
}

.p-t-1 {
  padding-top: 1em;
}

.p-l-1 {
  padding-left: 1em;
}
.p-l-2 {
  padding-left: 2em;
}
.p-l-3 {
  padding-left: 3em;
}
.p-l-4 {
  padding-left: 4em;
}

.btn:focus-visible {
  outline: solid -webkit-focus-ring-color !important;
  box-shadow: none !important;
}
