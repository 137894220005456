//== Colors
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
                (
                        "100": $gray-100,
                        "200": $gray-200,
                        "300": $gray-300,
                        "400": $gray-400,
                        "500": $gray-500,
                        "600": $gray-600,
                        "700": $gray-700,
                        "800": $gray-800,
                        "900": $gray-900
                ),
                $grays
);

$frost: #EEF2F3 !default;
$control: #F6F6F6 !default;          // #F9F9F9
$lightest-gray: rgb(227,227,227) !default;   // #E3E3E3
$light-gray: rgb(214,214,214) !default;      // #D6D6D6
$gray:rgb(153, 153, 153) !default;           // #999
$med-gray:rgb(102, 102, 102) !default;       // #666
$dark-gray: rgb(51, 51, 51) !default;        // #333


$blue: #007AFF !default;                     // #007AFF
$link-blue: rgb(0, 136, 204) !default;       // #08c
$light-blue: rgb(91,202,255) !default;       // #5BCAFF
$green: rgb(76,217,100) !default;            // #4CD964
$red: rgb(255,59,48) !default;               // #FF3B30
$pink: rgb(255,42,104) !default;             // #FF2A68
$orange: rgb(255,149,0) !default;            // #ff9500
$yellow: rgb(255,204,0) !default;            // #FFCCOO
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$faintest-blue: #F7FBFE !default;
$faint-blue: #E2F2FD !default;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$primary-disabled: #ceced0;

$theme-color-interval:      8% !default;
$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark,
        "control":    $control,
        "frost":      $frost,
        "primary-light": $light-blue
) !default;

//== Spacing and Borders
$border-radius: 5px;
$border-color: $light-gray;
$spacer: 16px;

//== Grid
$grid-breakpoints: (
        xs: 0,
        sm: 480px,
        md: 768px,
        lg: 1024px,
        xl: 1024.01px // hack to get around merge() and -assert-ascending plugin issue
);

$container-max-widths: (
        sm: 420px,
        md: 720px,
        lg: 960px,
        xl: 960.01px
);


//== Buttons
$min-width: 0;

/**
 * Whether or not the project has JS-based feature detection
 *
 * @group settings
 * @type Boolean
 * @access public
 */
$feature-detection: true !default;



//
// Bootstrap Overrides
// Always keep these at the end of the file
// -------------------------------------------------------

//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$gray-base:    $gray;
$gray-darker:  $dark-gray;
$gray-dark:    $med-gray;
$gray:         $gray;
$gray-light:   $light-gray;
$gray-lighter: $lightest-gray;


$brand-primary: $blue !default;
$brand-success: $green !default;
$brand-info:    $light-blue !default;
$brand-warning: $yellow !default;
$brand-danger:  $red !default;



//== Scaffolding
//
//## Settings for some of the most global styles.

//** Global text color on `<body>`.
$text-color:            $gray-darker !default;

//== Typography

$font-family-base:        "SF Text", -apple-system, -webkit-system-font, Helvetica, Arial, san-serif;
$font-size-base:          1rem !default;

$headings-font-weight: normal;
$headings-font-family: "SF Display", -apple-system, -webkit-system-font, Helvetica, Arial, san-serif;


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-bg:              mix($brand-success, #fff, 10%);
$state-success-text:            $brand-success;
$state-success-border:          $state-success-text;

$state-info-bg:                	 mix($brand-info, #fff, 10%);
$state-info-text:                darken($brand-info, 7%) !default;
$state-info-border:              $state-info-text;

$state-warning-bg:               mix($brand-warning, #fff, 10%);
$state-warning-text:             darken($brand-warning, 5%) !default;
$state-warning-border:           $state-warning-text;


$state-danger-bg:                mix($brand-danger, #fff, 10%);
$state-danger-text:              $brand-danger;
$state-danger-border:            $state-danger-text;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-default-color:              $gray-darker !default;
$btn-default-bg:                 $frost !default;
$btn-default-border:             $gray-light !default;

//== Forms
//
//##

//** Text color for `<input>`s
$input-color:                    $dark-gray !default;
$input-border-focus:             $blue !default;


//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:               15px !default;
$alert-link-font-weight:      bold !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;

///== Navbar
//
//##

// Basics of a navbar
$navbar-default-color:             #777 !default;
$navbar-default-bg:                $frost !default;
$navbar-default-border:            darken($navbar-default-bg, 6.5%) !default;

// Navbar links

$navbar-default-link-active-color:         $brand-primary !default;
$navbar-default-link-active-bg:            transparent !default;

// Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%) !default;
$navbar-inverse-bg:                         #222 !default;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color:                 $gray !default;
$navbar-inverse-link-hover-color:           #fff !default;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg:             transparent !default;
$navbar-inverse-link-disabled-color:        #444 !default;
$navbar-inverse-link-disabled-bg:           transparent !default;


//== Pagination
//
//##

$pagination-color:                     $gray-dark !default;
$pagination-border:                    #ddd !default;

$pagination-hover-color:               $white !default;
$pagination-hover-bg:                  $brand-primary !default;

$pagination-active-color:              $white !default;
$pagination-active-bg:                 $brand-primary !default;
$pagination-active-border:             $pagination-border !default;


//== Jumbotron
//
//##

$jumbotron-bg:                  $frost;


//== Panels
//
//##

//** Border color for elements within panels
$panel-default-border:        $gray-light !default;
$panel-default-heading-bg:    transparent !default;

$panel-primary-text:          $brand-primary!default;
$panel-primary-border:        $gray-light !default;
$panel-primary-heading-bg:    transparent !default;

$panel-success-border:        $gray-light !default;
$panel-success-heading-bg:    transparent !default;

$panel-info-border:           $gray-light !default;
$panel-info-heading-bg:       transparent !default;

$panel-warning-border:        $gray-light !default;
$panel-warning-heading-bg:    transparent !default;

$panel-danger-border:         $gray-light !default;
$panel-danger-heading-bg:     transparent !default;


//== Badges
//
//##

$badge-color:					$brand-primary !default;
//** Linked badge text color on hover
$badge-link-hover-color:		#fff !default;
$badge-bg:						#fff !default;

//** Badge background color in active nav link
$badge-font-weight:				normal !default;
$badge-border-radius:			10px !default;


//== Breadcrumbs
//
//##

//** Breadcrumb background color
$breadcrumb-bg:                 $frost !important;
$breadcrumb-color:              $gray-light !important;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       $gray-dark !important;


//== Labels
//
//##

//** Default label background color
$label-default-bg:            $gray !default;


//== Type
//
//##

//** Text muted color
$text-muted:                  $gray !default;
