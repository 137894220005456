$light-gray: #505458;
$lighter-gray: #393e42;
$background: #24292e;
$body-color: #fff;

.light-mode-only { display: block; }
.dark-mode-only { display: none; }

@media (prefers-color-scheme: dark) {
  html.style-root {
    &,body,.bg-white {
      color: $body-color !important;
      background-color: $background !important;
    }

    .navbar-brand-text {
      color: $body-color;
    }

    .light-mode-only { display: none; }
    .dark-mode-only { display: block; }

    .form-control, .btn-light {
      background-color: $lighter-gray !important;
      color: #ddd !important;
      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(128,128,128,0.15);
      }
      &:not(.is-valid,.is-invalid) {
        border-color: #737679 !important;
      }
    }
    select:not(:disabled):not(:is-valid):not(:is-invalid),
    .dropdown-toggle {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8bWV0YWRhdGE+aW1hZ2Uvc3ZnK3htbDwvbWV0YWRhdGE+CiA8Zz4KICAgPHBvbHlnb24gcG9pbnRzPSIxNi40IDExLjY1IDEyLjI2OTUgMTUuNTc0MiA4LjEgMTEuNjUgMTYuNCAxMS42NSIgZmlsbD0iI2RkZGRkZCIvPgogIDwvZz4KPC9zdmc+);
    }

    .bg-light { background-color: $light-gray !important; }
    .bg-light-on-hover:hover { background-color: #393e42; }
    // .bg-faintest-blue { background-color: #2e3f4a; }
    .bg-frost {
      background-color: #4d697c !important;
      &,input,textarea {
        color: #ddd;
      }
    }

    @each $color, $value in $theme-colors {
      .bg-light-#{$color} {
        background-color: mix($background, theme-color-level($color, 0), 65%);
      }
    }

    .text-dark {color: $body-color !important;}

    .border, .border-top, .border-bottom, .table td, .table th, .dropdown-toggle, .dropdown-menu {
      border-color: $light-gray !important;
    }

    .bg-control, .btn-control {
      background-color: $lighter-gray !important;
      &,input,textarea {
        color: #ddd;
      }
    }
    .btn-group > .btn-control {
      border-color: $light-gray !important;
      &.active {
        background-color: $light-gray !important;
        color: #eee !important;
      }
    }
    .list-group,.list-group-item {
      background-color: inherit;
      border-color: $lighter-gray;
    }

    .triangle-right-frost {
      border-left-color: #4d697c !important;
    }

    hr {
      border-top: 1px solid rgba(255, 255, 255, 0.1)
    }

    .dropdown-menu {
      background-color: $background;
      color: $body-color;
    }
    .dropdown-item {
      color: $body-color;
    }
    .dropdown-divider{
      border-color: $light-gray
    }

    // Datepickers
    .DateRangePickerInput, .SingleDatePickerInput {
      //@extend .rounded;
      background-color: $light-gray !important;
      &,input,textarea {
        color: #ddd;
      }
    }
    .DateRangePicker_picker {
      background-color: transparent;
    }
    .DayPicker__withBorder { border: 1px solid $lighter-gray;}
    .CalendarMonth, .CalendarMonthGrid, .DayPicker, .DayPicker__horizontal {
      background-color: $background;
    }
    .CalendarDay__default, .CalendarDay__default, .DayPickerNavigation_button__default {
      color: inherit;
      background-color: $background;
      border-color: $lighter-gray;
      &:hover {
        background-color: $lighter-gray;
        border-color: $light-gray;
      }
      &.CalendarDay__blocked_out_of_range {
        color: $light-gray;
      }
      &.CalendarDay__selected {
        background-color: $primary;
        border-color: darken($primary, 5%);
      }
      &.CalendarDay__selected_span {
        background-color: darken($primary, 30%);
        border-color: darken($primary, 35%);
      }
      &.CalendarDay__hovered_span {
        background-color: darken($primary, 10%);
        border-color: darken($primary, 15%);
      }
      &.CalendarDay__highlighted {
        background-color: #ebec8f;
      }
    }
    .CalendarMonth_caption {
      color: inherit;
    }
    .DateInput_fangShape {
      fill: $background;
    }
    .DateInput_fangStroke {
      stroke: $lighter-gray;
    }

    // D3 Charts
    text[fill="#000"],text[fill="#000000"] {
      fill: #ddd;
    }
    line[stroke="#000"],line[stroke="#000000"],
    path[stroke="#000"],path[stroke="#000000"] {
      stroke: #ddd;
    }

    // React Table
    .ReactTable {
      border-color: $dark-gray;

      .rt-tr.-odd .rt-td {
        background: $lighter-gray;
      }
      .rt-th,.rt-td {
        background: $background;
      }
      &.-striped .rt-tr.-odd {
        background: rgba(255,255,255,0.05);
      }
      * {
        border-color: $dark-gray !important;
      }
      .rt-thead.-headerGroups {
        background: $light-gray;
        border-bottom-color: $light-gray !important;
        .rt-tr .rt-th {
          background: $light-gray;
        }
      }
    }

    body * {
      ::-webkit-scrollbar-track {
          background-color: $lighter-gray;
      }
      ::-webkit-scrollbar-thumb {
          background-color: #bbbbbb99;
      }
    }
    
    .btn-light:not(.disabled):not(:disabled):hover {
      background-color: lighten($lighter-gray, 5%) !important;
    }
    .btn-light:not(.disabled):not(:disabled):active {
      background-color: lighten($lighter-gray, 10%) !important;
    }
    .dropdown.show .btn-light:not(.disabled):not(:disabled) {
      background-color: lighten($lighter-gray, 10%) !important;
    }
    .dropdown-item:hover, .dropdown-item:focus {
      background-color: $lighter-gray;
    }
  }
}
